import Image from 'next/image'
import { FC } from 'react'

import LocalizedObject from '../../types/LocalizedObject'

const icons: LocalizedObject = {
  'de-de': 'de.svg',
  'da-dk': 'da.svg',
  'en-gb': 'gb.svg',
  'en-au': 'au.svg',
  'en-us': 'us.svg',
  'en-ca': 'en-ca.svg',
  'es-es': 'es.svg',
  'fr-fr': 'fr.svg',
  'it-it': 'it.svg',
  'nl-nl': 'nl.svg',
  'nb-no': 'no.svg',
  'sv-se': 'sv.svg',
}

type PropTypes = {
  locale: string
}

const Flags: FC<PropTypes> = ({ locale }) => {
  const localizedIcon = `/icons/flags/${icons[locale]}`
  return <Image src={localizedIcon} width={22} height={22} alt={`Flag for ${locale}`} unoptimized />
}

export default Flags
