import { useRouter } from 'next/router'
import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'

import ActionDiv from '../actiondiv/ActionDiv'
import Check from '../icons/Check'
import DownArrow from '../icons/DownArrow'
import Flags from '../icons/Flags'
import { languageStringFromLocale } from './LanguageHelper'

import styles from './LanguagePicker.module.scss'
import { Link } from 'components/link/Link'
import { useOuCookies } from 'components/cookiebanner/CookieBanner'
import { PageAlternateLink, useLink } from 'context/LinkContext'

type PropTypes = {
  className: string
  pageAlternates?: PageAlternateLink[]
}

const fallbackLanguage = 'en-gb'

export const LanguagePicker: FC<PropTypes> = ({ className, pageAlternates }) => {
  const { setCookies, consentNecessary, NEXT_LOCALE } = useOuCookies()
  const router = useRouter()
  const { getLocalePath } = useLink()
  const node = useRef<HTMLDivElement>(null)
  const [isListOpen, toggleList] = useState(false)

  useEffect(() => {
    if (isListOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [isListOpen])

  const handleOutsideClick = (e: MouseEvent): void => {
    if (!node?.current.contains(e.target as Node)) {
      toggleList(false)
    }
  }

  const selectLanguageClick = (event: SyntheticEvent, href: string): void => {
    toggleList(false)
    event.preventDefault()
    const { pathname, query } = router
    // get rid of the protocol
    const url = href?.split('//')[1]

    // get rid of the host
    const indexOfSlash = url?.indexOf('/')
    const slug = indexOfSlash > -1 ? url?.substring(indexOfSlash).slice(1) : '/'

    // get the locale from the slug
    const [locale] = slug?.split('/')

    // get rest of the path from the slug
    const path = slug?.substring(slug?.indexOf('/'))

    const fallbackLocale = locale === '' ? fallbackLanguage : locale
    const fallbackPath = path === '' ? fallbackLanguage : path

    if (consentNecessary && NEXT_LOCALE !== fallbackLocale) {
      setCookies('NEXT_LOCALE', fallbackLocale)
    }

    // Special hack relating to the Kundo implementation.
    // router.push does not cause a refresh of the page,
    // which is required to reload the Kundo-script.
    // Therefore we use window.location.href instead.
    const enGbPath = getLocalePath(router.asPath, 'en-gb')
    if (enGbPath.toLowerCase().indexOf('/help/faq') > -1) {
      window.location.href = '/' + fallbackLocale + fallbackPath
    } else {
      router.push({ pathname, query }, fallbackPath, { locale: fallbackLocale, scroll: false })
    }
  }

  const linkMapper = (locale: string): JSX.Element => {
    const selected = router.locale.toLowerCase() === locale.toLowerCase()

    let href = pageAlternates?.find((link) => link.hreflang.toLowerCase() === locale.toLowerCase())?.href
    if (!href) {
      href = pageAlternates?.find((link) => link.hreflang === 'x-default')?.href
    }

    const host = href?.split('//')[1]
    const indexOfSlash = host?.indexOf('/')

    const slug = indexOfSlash > -1 ? host?.substring(host.indexOf('/')) : '/'
    return (
      <Link key={locale} href={`${slug}`} locale={false} className={styles.listitem} onClick={(event) => selectLanguageClick(event, href)}>
        <Flags locale={locale} />
        {languageStringFromLocale(locale)}
        {selected && <Check />}
      </Link>
    )
  }

  const zStyle = isListOpen ? styles.zIndex : ''

  return (
    <div ref={node} className={className ? `${styles.container} ${className} ${zStyle}` : `${styles.container} ${zStyle}`}>
      <ActionDiv className={styles.header} onClick={() => toggleList(!isListOpen)} ariaLabel="language-picker">
        <Flags locale={router.locale} />
        <p>{languageStringFromLocale(router.locale)}</p>
        <DownArrow />
      </ActionDiv>
      <ul className={styles.list} style={{ bottom: -router.locales.length * 24.6, maxHeight: 'initial', display: isListOpen ? 'block' : 'none' }}>
        {router.locales.map(linkMapper)}
      </ul>
    </div>
  )
}
