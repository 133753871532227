import Head from 'next/head'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import Footer from '../components/storybloks/footer/Footer'
import DynamicComponent from './StoryblokComponents'
import Header from './storybloks/header/Header'
import TopBanner from './storybloks/topbanner/TopBanner'
import SmartBannerScript from 'lib/smartbanner'
import { PageAlternateLink } from 'context/LinkContext'

type PropTypes = {
  content: StoryblokContent
  children: JSX.Element | JSX.Element[]
  language?: string
  noIndex?: boolean
  pageAlternates?: PageAlternateLink[]
}

const Layout: FC<PropTypes> = ({ content, children, pageAlternates }) => {
  const header = content.body.find((component: StoryblokContent) => component.component === 'header')
  const topBanner = content.body.find((component: StoryblokContent) => component.component === 'top_banner')
  const topBannerIsShowing = topBanner && topBanner.hide === false
  const footer = content.body.find((component: StoryblokContent) => component.component === 'footer')
  const ignoredComponents = ['header', 'top_banner', 'footer']
  const dynamicLayoutComponents = content.body.filter(
    (component: StoryblokContent) => ignoredComponents.find((ignoredComponent) => ignoredComponent === component.component) === undefined
  )
  const faviconPath =
    process.env.NODE_ENV === 'development' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ? '/favicon_preview.ico' : '/favicon.ico'

  return (
    <>
      <Head>
        <link rel="icon" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#FAE7A2" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Onceuponapp1" />
        <meta name="twitter:creator" content="@Onceuponapp1" />
        <meta name="keywords" content="photobook" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta property="og:site_name" content="Once Upon" />
        <meta property="fb:app_id" content="138869076739678" />
        <meta name="facebook-domain-verification" content="2u68bfxedafc28yxoiypbbz7hm4qww" />
      </Head>
      <SmartBannerScript />
      <Header blok={header} topBannerIsShowing={topBannerIsShowing} />
      <TopBanner blok={topBanner} />

      {children}

      {dynamicLayoutComponents.map((blok: StoryblokContent) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}
      <Footer blok={footer} links={header.links} pageAlternates={pageAlternates} />
    </>
  )
}

export default Layout
