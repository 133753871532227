import { FC } from 'react'

type PropTypes = {
  dark?: boolean
}
const UpArrow: FC<PropTypes> = ({ dark }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
    <path
      fill={dark ? '#46505a' : '#ffffff'}
      d="M9.293 21.707a1 1 0 001.414 0L18 14.415l7.293 7.292a1 1 0 001.32.083l.094-.083a1 1 0 000-1.414l-8-8a1 1 0 00-1.414 0l-8 8a1 1 0 000 1.414z"
    ></path>
  </svg>
)

export default UpArrow
